<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="订单状态" prop="state">
                <a-select placeholder="请选择订单状态" v-model="queryParam.state" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.order_state" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="接单时间" prop="startTime">-->
<!--                <a-date-picker style="width: 100%" v-model="queryParam.startTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:order/attest:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)">
          <a-icon type="edit" />修改
        </a-button>
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:order/attest:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:order/attest:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :stateOptions="dict.type.order_state"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="avatar" slot-scope="text, record">
          <img v-if="record.avatar" :src="record.avatar" alt="图片" style="height:48px;max-width:48px"/>
<!--          -->
<!--            <img style="width: 40px;height: 40px;margin-top: 5px;margin-right: 5px;" v-if="record.avatar"-->
<!--                 v-for="(guildLogo,imgIndex) in record.avatar.split(',')"-->
<!--                 :src="guildLogo"-->
<!--                 preview="图片" />-->
        </span>

        <span slot="state" slot-scope="text, record">
          <dict-tag :options="dict.type['order_state']" :value="record.state"/>
        </span>
        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          state  1待支付 2已支付 3已取消 4待接单 5进行中 6确认完成 7完成-状态客户确认中 -->
          <a v-show="record.state === 4" @click="handleAccept(record)">
            <a-icon type="edit" />接单
          </a>
          <a-divider v-show="record.state === 4" type="vertical"  />

          <a v-show="record.state === 5" @click="handleDone(record)">
            <a-icon type="edit" />完成
          </a>
          <a-divider v-show="record.state === 5" type="vertical"  />

          <a v-show="record.state === 5" @click="$refs.createForm.handleUpdate(record, undefined)" >
            <a-icon type="edit" />上传bp
          </a>
          <a-divider v-show="record.state === 5" type="vertical"  />

          <a @click="handleDelete(record)" >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  pageOrderAttest,
  listOrderAttest,
  delOrderAttest,
  acceptOrderAttest,
  doneOrderAttest
} from '@/api/biz/orderAttest'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import ImageViewer from "@/components/Viewer/ImageViewer.vue";

export default {
  name: 'OrderAttest',
  components: {
    ImageViewer,
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['order_state'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        state: null,
        startTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '用户ID',
        //   dataIndex: 'attestId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '项目ID',
        //   dataIndex: 'orderId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "avatar"}
        },
        {
          title: '描述',
          dataIndex: 'description',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '订单状态',
          dataIndex: 'state',
          scopedSlots: { customRender: 'state' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '接单时间',
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '派单时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '文件名称',
          dataIndex: 'files',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询梦之投接单记录列表 */
    getList () {
      this.loading = true
     pageOrderAttest(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        state: undefined,
        startTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    handleAccept (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认接单吗?',
        content: '',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return acceptOrderAttest(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },

    handleDone (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认已完成完成吗?',
        content: '',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return doneOrderAttest(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delOrderAttest(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/con-order-attest/export', {
            ...that.queryParam
          }, `梦之投接单记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
