<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="文件名称" prop="attestId" >
        <a-input v-model="form.fileName" placeholder="请输入文件名称" />
      </a-form-model-item>
      <a-form-model-item label="参数键值" prop="configValue">
        <file-upload2 type="video" :count="1" v-model="form" fieldName="bpFile"></file-upload2>
      </a-form-model-item>

<!--      <a-form-model-item label="用户ID" prop="attestId" >-->
<!--        <a-input v-model="form.attestId" placeholder="请输入用户ID" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="项目ID" prop="orderId" >-->
<!--        <a-input v-model="form.orderId" placeholder="请输入项目ID" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="订单状态" prop="state" >-->
<!--        <a-select placeholder="请选择订单状态" v-model="form.state">-->
<!--          <a-select-option v-for="(d, index) in stateOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="接单时间" prop="startTime" >-->
<!--        <a-date-picker style="width: 100%" v-model="form.startTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getOrderAttest, addOrderAttest, updateOrderAttest } from '@/api/biz/orderAttest'
import Editor from "@/components/Editor";

export default {
  name: 'CreateForm',
  props: {
    stateOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        attestId: null,

        orderId: null,

        state: null,

        startTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        attestId: null,
        orderId: null,
        state: null,
        startTime: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },

    // handleAccept (row) {
    //   var that = this
    //   const ids = row.id || this.ids
    //   if(row.id){
    //     this.ids.push(row.id)
    //   }
    //   this.$confirm({
    //     title: '确认接单吗?',
    //     content: '当前选中的数据',
    //     onOk () {
    //       let data = []
    //       that.ids.map(id => data.push({"id":id}))
    //       return acceptOrderAttest(data)
    //         .then(() => {
    //           that.onSelectChange([], [])
    //           that.getList()
    //           that.$message.success(
    //             '接单成功',
    //             3
    //           )
    //         })
    //     },
    //     onCancel () {
    //       //取消清空删除ids列表
    //       that.ids = []
    //     }
    //   })
    // },

    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOrderAttest({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOrderAttest(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addOrderAttest(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
