import request from '@/utils/request'


// 查询梦之投接单记录列表
export function listOrderAttest(query) {
  return request({
    url: '/biz/con-order-attest/list',
    method: 'get',
    params: query
  })
}

// 查询梦之投接单记录分页
export function pageOrderAttest(query) {
  return request({
    url: '/biz/con-order-attest/pageOrder',
    method: 'get',
    params: query
  })
}

// 查询梦之投接单记录详细
export function getOrderAttest(data) {
  return request({
    url: '/biz/con-order-attest/detail',
    method: 'get',
    params: data
  })
}

// 新增梦之投接单记录
export function addOrderAttest(data) {
  return request({
    url: '/biz/con-order-attest/add',
    method: 'post',
    data: data
  })
}

// 修改梦之投接单记录
export function updateOrderAttest(data) {
  return request({
    url: '/biz/con-order-attest/bpFile',
    method: 'post',
    data: data
  })
}

// 删除梦之投接单记录
export function delOrderAttest(data) {
  return request({
    url: '/biz/con-order-attest/delete',
    method: 'post',
    data: data
  })
}

export function acceptOrderAttest(data) {
  return request({
    url: '/biz/con-order-attest/accept',
    method: 'post',
    data: data
  })
}

export function doneOrderAttest(data) {
  return request({
    url: '/biz/con-order-attest/done',
    method: 'post',
    data: data
  })
}


